.text-align-center {
  text-align: center;
}

.display-block {
  display: block;
}

.margin-auto {
  margin: auto;
}

.font-style-italic {
  font-style: italic;
}

.position-absolute {
  position: absolute;
}

.position-fixed-submenu {
}

.position-relative {
  position: relative;
}
/***********************************************************************************/
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
  font-weight: 900;
}
body {
  margin: 0;
  font-family: 'Roboto', 'quicksand', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ececec;
}

code {
  font-family: 'Roboto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
  background-color: #f5f5f5;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10rem;
  background-color: #979393;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777777;
}

/*************************** Other classes for components *******************************/
.selectedRowTable :hover {
  cursor: pointer;
}
.ant-layout {
  background: white;
}
.ant-layout-header {
  background-color: transparent;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 1px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 1px;
  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 1px;
}

.site-card-border-less-wrapper {
  /* padding: 10rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  max-width: 100vw;
  position: relative;
}

.site-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.site-card-logo {
  width: 230px;
  height: 230px;
  object-fit: contain;
  position: absolute;
  top: 6%;
}

@media screen and (max-width: 600px) {
  .site-card-logo {
    width: 150px;
    height: 150px;
    position: relative;
  }
}

@media screen and (max-height: 800px) {
  .site-card-logo {
    position: relative;
  }
}
@media screen and (max-height: 500px) {
  .site-card-border-less-wrapper {
    height: unset !important;
  }
}

.register-card-layout {
  width: 35rem;
  max-width: 95vw;
  margin: auto;
}

.createRp-card-layout {
  width: 36rem;
  margin: auto;
  margin-top: 2rem;
}

.contact-address {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.card-number {
  /* width: -webkit-fill-available; */
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
}

.recaptcha-code {
  width: 50%;
  margin-bottom: 2rem;
}

.active-email-alert {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  padding: 1rem;
  font-size: large;
  left: 0;
}
.yellowButton,
.yellowButton:active,
.yellowButton:focus {
  color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}

.yellowButton:hover {
  background-color: rgba(250, 140, 22, 0.048);
}

.greenButton,
.greenButton:active,
.greenButton:focus {
  color: #82be07 !important;
}

.greenButton:hover {
  border-color: #82be07 !important;
}

.redButton,
.redButton:active,
.redButton:focus {
  color: #eb2f96 !important;
  border-color: #eb2f96 !important;
}

.redButton:hover {
  background-color: rgba(235, 47, 150, 0.048);
}

.yellowButtonBg,
.yellowButtonBg:active,
.yellowButtonBg:focus {
  color: white !important;
  border-color: #fa8c16 !important;
  background-color: #fa8c16 !important;
}

.yellowButtonBg:hover {
  background-color: rgb(252, 159, 59) !important;
}

.grayButtonBg,
.grayButtonBg:active,
.grayButtonBg:focus {
  color: white !important;
  border-color: gray !important;
  background-color: gray !important;
}

.grayButtonBg:hover {
  background-color: rgb(146, 146, 146) !important;
}

.dangerButtonBg,
.dangerButtonBg:active,
.dangerButtonBg:focus {
  color: white !important;
  border-color: #d33f29 !important;
  background-color: #d33f29 !important;
}

.dangerButtonBg:hover {
  background-color: #e65d48 !important;
}

.greenButtonBg,
.greenButtonBg:active,
.greenButtonBg:focus {
  color: white !important;
  border-color: #82be07 !important;
  background-color: #82be07 !important;
}

.greenButtonBg:hover {
  background-color: #9ccc3d !important;
}

.margin-left {
  margin-left: 8px;
}
.spin-style {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spin-style-suspense__parent {
  width: 100%;
  height: 100%;
  position: relative;
}
.spin-style-suspense {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ////////////////////////////// */
.ant-table {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 1px;
}

.layout-footer-btns {
  margin: 0 8px;
}
.sidebarImageBotom {
  text-align: center;
  max-width: 80%;
  max-height: 80%;
}
.sidebarImageBotomContainer {
  padding: 0;
  box-shadow: rgb(0 0 0 / 150%) 0px 2px 2px -2px;
  -webkit-box-shadow: rgb(0 0 0 / 15%) 0px 2px 2px -2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerImageIdmelon {
  width: auto;
  height: 80%;
}
.expiredLicense {
  color: #d43e2a !important;
}
.WarningBackground {
  background-color: #ffec3d;
}
.pendingLicense {
  background-color: rgb(248, 248, 195) !important;
}
.grayBlueButton,
.grayBlueButton:active,
.grayBlueButton:focus {
  color: #99bdc2 !important;
  border-color: #99bdc2 !important;
}
.grayBlueButton:hover {
  color: #808080 !important;
  border-color: #808080 !important;
}
.loadingOnePage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}
.main-layout-content {
  padding: 1rem 1.5rem;
  overflow: initial;
  background-color: #f0f2f5;
}
.fiteringSpace {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.filteringSizeWidth {
  width: 30%;
}
.containerFiltering {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
@media screen and (max-width: 768px) {
  .filteringSizeWidth {
    width: 100%;
  }
}
.breadcrumbLink:hover {
  text-decoration: underline;
}

.main-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.main-content::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
  background-color: #f5f5f5;
}

.main-content::-webkit-scrollbar-thumb {
  border-radius: 10rem;
  background-color: #808080;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 0.7rem;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10rem;
  background-color: #979393;
}

.panel-active-menu-item {
  color: #7e94b3;
}

.fetching {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0px;
  left: 0px;
}

.fade-enter {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-appear {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-leave {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-appear.fade-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.steps-content {
  min-height: 100%;
  margin-top: 1rem;
  padding: 1rem;
  text-align: left;
  /* background-color: #fafafa; */
  border: 0.1rem solid #7e94b3;
  border-radius: 2px;
}

.steps-action {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.warning-color {
  color: #ffaf40;
}
.success-color {
  color: #00be4d;
}
.error-color {
  color: #d3000d;
}
.text-primary {
  color: #1e1e1e;
}
.text-secondary {
  color: #333333;
}
.menuItem {
  padding-left: 2rem !important;
}
.groupMenuStyle {
  padding: 8px 16px !important;
}
.cardLaunchpad:hover {
  transform: scale(1.02);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 1px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 1px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 1px;
}
@media screen and (max-width: 992px) {
  .cardLaunchpad {
    height: unset !important;
    margin-top: 2rem;
  }
}
.cardLaunchpad {
  /* min-width: 18rem; */
  width: 100%;
  height: 100%;
  border-radius: 5%;
  cursor: pointer;
  border: 1px solid grey;
  transition: transform 0.2s;
}
.cardImageLaunchpad {
  max-width: 100%;
  max-height: 100%;
}
.headerLaunchpad {
  font-size: 1.6rem;
  text-align: center;
  color: #37474f;
}
.descriptionLaunchpad {
  font-size: 1.4rem;
  text-align: center;
  color: #777777;
  margin-bottom: 2rem;
}
.footerLaunchpad {
  font-size: 1.2rem;
  color: #1337ee;
  margin-top: 2rem;
}
.cardTextLaunchpad {
  font-size: 0.85rem;
  text-align: center;
  color: grey;
  padding-top: 1rem;
}
.cardImageContainerLaunchpad {
  width: 100%;
  height: 35%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.cardImportUser {
  width: 200px;
  height: 200px;
  border: 1px solid grey;
  border-radius: 25px;
  transition: transform 0.2s;
}
@media screen and (max-width: 992px) {
  .cardImportUser {
    margin: 25px auto;
  }
}
.cardImportUser:hover {
  cursor: pointer;
  transform: scale(1.02);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 1px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 1px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 1px;
}
.cardActionImportUser {
  font-size: 1.2rem;
  text-align: center;
  color: #434343;
  margin: 0;
}
.cardIconImportUser {
  margin: auto;
  display: block;
  width: 76px;
  height: 76px;
  margin-bottom: 20px;
}

.containerLanchpad {
  width: 90%;
}

.account-recovery-modal__options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 3px;
  padding: 10px 8px;
  height: 120px;
  cursor: pointer;
  transition: all 0.3s;
  width: 190px;
  margin: 8px 0;
}

.account-recovery-modal__options:hover {
  transform: translateY(-3px);
}

.account-recovery-modal__options h3 {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 700;
  margin: 0 auto;
}

.account-recovery-modal {
  min-height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
.left-enter {
  opacity: 0.8;
  transform: translateX(-2%);
}
.left-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.left-exit {
  opacity: 0.7;
  transform: translateX(0%);
}
.left-exit-active {
  opacity: 0;
  transform: translateX(4%);
}
.left-enter-active,
.left-exit-active {
  transition: opacity 250ms, transform 220ms;
}

.right-enter {
  opacity: 0.8;
  transform: translateX(4%);
}
.right-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.right-exit {
  opacity: 0.7;
  transform: translateX(0%);
}
.right-exit-active {
  opacity: 0;
  transform: translateX(-4%);
}
.right-enter-active,
.right-exit-active {
  transition: opacity 250ms, transform 220ms;
}
.table-header-tab-number {
  font-size: 1.7rem;
  font-weight: 900;
  display: block;
  margin: 0;
}

.obr-img {
  transform: rotate(30deg);
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Roboto;
  position: relative;
  background: #ffffff;
  border-radius: 4px;
}
.ant-card-head {
  padding: 0 24px;
  border-bottom: 1px dashed #f0f0f0 !important;
}
.ant-card-body {
  padding: 24px;
}
.ant-btn {
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 4px;
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  color: rgba(0, 0, 0, 0.88);
}
.ant-btn-primary {
  color: #fff;
  background-color: #0186c2;
  box-shadow: 0 2px 0 rgba(5, 215, 255, 0.1);
}
.ant-btn-link {
  color: #0186c2;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Roboto;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 4px;
  transition: all 0.2s;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input {
  border-color: #ff4d4f;
}
.ant-form-item .ant-form-item-explain-error {
  color: #ff4d4f;
}
.ant-icon {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: 'Roboto';
  position: fixed;
  z-index: 1050;
  margin-inline-end: 24px;
  margin-right: 24px;
}

.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 48px);
  margin-bottom: 16px;
  margin-inline-start: auto;
  padding: 20px 24px;
  overflow: hidden;
  line-height: 1.5714285714285714;
  word-wrap: break-word;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  margin-inline-start: 36px;
  font-size: 16px;
  margin-left: 36px;
}
.ant-notification-notice.ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-inline-end: 24px;
}
.ant-notification-notice-icon {
  position: absolute;
  font-size: 24px;
  line-height: 0;
}
.ant-notification-notice-description {
  margin-inline-start: 36px;
  font-size: 14px;
}
.ant-notification-notice-close {
  position: absolute;
  top: 20px;
  inset-inline-end: 24px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px !important;
}

.ant-spin-nested-loading .ant-spin-blur {
  clear: both;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}

.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading .ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}

.div-container {
  background-color: white;
}
.ant-card-head-title {
  padding: 8px 0;
}
.ant-card-body {
  padding-bottom: 8px;
  padding-top: 8px;
}
.listOfWorkspaces:hover {
  cursor: pointer;
  background-color: #fafafa;
}
.listOfWorkspaces {
  width: 100%;
  display: block;
  padding: 1rem;
}
.workspaceIcon {
  width: auto;
  height: 50px;
}

.card-button {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 1rem;
}
.card-button:hover {
  cursor: pointer;
  background-color: #188fff26;
  border-color: #1890ff;
  transition-duration: 0.3s;
}

.card-button-active {
  background-color: #188fff26;
  border-color: #1890ff;
}

.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Roboto;
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-modal {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Roboto;
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  padding-bottom: 24px;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  left: 0;
  right: 0;
}
.ant-modal-root .ant-modal-wrap {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  left: 0;
  right: 0;
}
.ant-modal-root .ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: start;
  vertical-align: middle;
  width: 520px;
}
.ant-modal-root .ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-header {
  padding: 12px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #f1f1f1;
  border-radius: 8px 8px 0 0 !important;
}

.ant-modal .ant-modal-header {
  padding: 12px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #f1f1f1;
  border-radius: 8px 8px 0 0 !important;
}
.ant-modal-title {
  line-height: 22px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85) !important;
  margin: 0;
  word-wrap: break-word;
  font-weight: 500 !important;
}

.ant-modal .ant-modal-close {
  position: absolute;
  top: 15px;
  inset-inline-end: 15px;
  z-index: 1010;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border-radius: 4px;
  width: 22px;
  height: 22px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
  right: 15px;
}

.ant-modal-close-x {
  display: flex;
  font-style: normal;
  justify-content: center;
  text-transform: none;
  text-rendering: auto;
  font-size: 1rem;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px !important;
}
.ant-modal-root .ant-modal-wrap {
  z-index: 1000;
  position: fixed;
  inset: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.ant-modal-root .ant-modal-wrap {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  right: 0;
  bottom: 0;
  inset-inline-start: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-body {
  padding: 24px !important;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Roboto;
  position: relative;
  display: inline-block;
  outline: none;
  cursor: pointer;
  align-self: center;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Roboto;
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-inline-start: 0;
  margin-inline-end: 8px;
  cursor: pointer;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Roboto;
  display: inline-block;
}
.ant-radio-input {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.ant-radio-wrapper .ant-radio-input {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-checked .anr-radio-inner {
  border-color: #1890ff;
  background-color: #fff !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #0186c2;
  background-color: #fff !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper span.ant-radio + * {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  padding: 0px 8px;
}

.ant-radio-inner::after {
  box-sizing: border-box;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-block-start: -8px;
  margin-inline-start: -8px;
  background-color: #fff;
  border-block-start: 0;
  border-inline-start: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: '';
}

.ant-card .ant-card-head {
  border-bottom: 1px solid #f0f0f0 !important;
  border-radius: 2px 2px 0 0 !important;
}
.ant-radio-wrapper .ant-radio-inner::after {
  box-sizing: border-box;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-block-start: -8px;
  margin-inline-start: -8px;
  background-color: #1890ff;
  border-block-start: 0;
  border-inline-start: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: '';

  -webkit-margin-before: -8px;
  -webkit-margin-start: -8px;
  -webkit-border-before: 0;
  -webkit-border-start: 0;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  margin-left: -8px;
}

.footer {
  display: flex;
}
footer h4.footer__description {
  font-size: 12px;
  font-weight: 500;
  color: #5e5e5e;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .footer {
    justify-content: center;
  }
  footer h4.footer__description {
    max-width: 80%;
    text-align: center;
    margin-left: 0;
  }
}

.required {
  color: black;
}
.required:after {
  content: ' *';
  color: red;
  font-size: medium;
}

.count-down--1 div .ant-statistic-content-value {
  font-size: 1.5rem;
}

.active-card-button {
  border: 1px solid #eaebfb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}
.active-card-button:hover {
  cursor: pointer;
  background-color: #e6f7ff;
  border-color: #eaebfb;
  color: #0186c2;
  transition-duration: 0.3s;
}

.active-card-button[aria-disabled='true'] {
  cursor: not-allowed;
  background-color: #fafafa;
}

.active-card-button--checked {
  background-color: #e6f7ff;
  border-color: #eaebfb;
  color: #0186c2;
}

.card-box__checkbox .ant-checkbox-checked::after {
  /* border:none; */
  border-radius: 50%;
}
.card-box__checkbox .ant-checkbox .ant-checkbox-inner {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.card-box__checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  left: 35%;
}

.workspaces-cont {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.workspaces-cont::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
